import { useEffect, useRef, useState, useCallback } from "react";
import TagManager from "react-gtm-module";
import { GetContinueToQueryString } from "../../../lib/PassedParams";
import MobileLeftNavMenu from "../MobileLeftNavMenu/MobileLeftNavMenu";

// Context
import {
  navigateTo,
  GetOrCreateSessionAuthContext,
} from "../../../config/UserAuthContext";
import { DeviceSessionContext } from "../../../components/analytics/DeviceContext";
import { useFeatureFlags } from "../../../config/FeatureFlagContext";
import Cookies from "js-cookie";

// Images
import UserMenu from "../UserMenu/UserMenu";
import logo from "../../../global-assets/assets/truplay-logo-large.png";
import UserIcon from "../../../global-assets/assets/User_Icon.svg";
import MenuIcon from "../../../global-assets/assets/icon-hamburger-menu.svg";
import ChevronRightTeal from "../../../global-assets/assets/chevron-right-teal.svg";
import ChevronRightWhite from "../../../global-assets/assets/chevron-right.svg";
import NavBarLight from "./NavBarLight";
import Button from "../../common/Button";

interface NavBarProps {
  isAuthenticated: any;
  emailAddress: any;
  showMobileLogo?: boolean;
  light?: boolean;
}

const dropdownItemStyles =
  "hover:bg-menu_active cursor-pointer whitespace-nowrap px-[1.75rem] py-4 text-xl font-normal" +
  " leading-5 text-white transition-colors duration-200 hover:drop-shadow-[0px_0px_16px_#9CD7D4";

/**
 *
 * @param setAuth
 * @constructor
 */
export default function NavBar({
  isAuthenticated,
  emailAddress,
  showMobileLogo = false,
  light = false,
}: NavBarProps) {
  const initialUserContext = GetOrCreateSessionAuthContext();
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const [userContext] = useState(initialUserContext);
  const [deviceCtx] = useState<DeviceSessionContext>(
    new DeviceSessionContext()
  );
  const GlobalUserId = Cookies.get("GlobalUserId");
  const featureFlags = useFeatureFlags();
  const FreeTrial30 = featureFlags["2024-04 - 30 Day Free Trial"]?.variationId;
  const [freeTrial30, setFreeTrial30] = useState(false);

  // Ref for User Icon circle, used to anchor the menu at large screen widths
  const userIconRef = useRef<HTMLInputElement>(null);
  // User Icon circle element's positions, used to determine menu width
  const userIconPositions = useRef<DOMRect | undefined>();
  // Updates the User Icon circle position as the screen width changes
  const updateUserIconPos = useCallback(() => {
    userIconPositions.current = userIconRef.current?.getBoundingClientRect();
  }, []);

  useEffect(() => {
    if (userIconPositions.current === undefined) {
      userIconPositions.current = userIconRef.current?.getBoundingClientRect();
    }
    window.addEventListener("resize", updateUserIconPos);
    return () => window.removeEventListener("resize", updateUserIconPos);
  }, [updateUserIconPos]);

  // Ref for the User Menu, used to check if the user is clicking within or outside it
  const userMenuRef = useRef<HTMLDivElement>(null);
  const closeUserMenu = useCallback(
    (e: any) => {
      if (isUserMenuOpen && !userMenuRef.current?.contains(e.target)) {
        setIsUserMenuOpen(false);
      }
    },
    [isUserMenuOpen]
  );

  useEffect(() => {
    window.addEventListener("mousedown", closeUserMenu);
    return () => window.removeEventListener("mousedown", closeUserMenu);
  }, [closeUserMenu]);

  // Ref for the left Mobile Menu, used to check if the user is clicking within or outside it
  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const closeMobileMenu = useCallback(
    (e: any) => {
      if (isMobileMenuOpen && !mobileMenuRef.current?.contains(e.target)) {
        setIsMobileMenuOpen(false);
      }
    },
    [isMobileMenuOpen]
  );

  useEffect(() => {
    window.addEventListener("mousedown", closeMobileMenu);
    return () => window.removeEventListener("mousedown", closeMobileMenu);
  }, [closeMobileMenu]);

  // Ref for the left More Dropdown Menu, used to check if the user is clicking within or outside it
  const moreMenuRef = useRef<HTMLDivElement>(null);
  const closeMoreMenu = useCallback(
    (e: any) => {
      if (isMoreMenuOpen && !moreMenuRef.current?.contains(e.target)) {
        setIsMoreMenuOpen(false);
      }
    },
    [isMoreMenuOpen]
  );

  useEffect(() => {
    window.addEventListener("mousedown", closeMoreMenu);
    return () => window.removeEventListener("mousedown", closeMoreMenu);
  }, [closeMoreMenu]);

  useEffect(() => {
    if (FreeTrial30) {
      switch (FreeTrial30) {
        case "1":
          break;
        case "2":
          setFreeTrial30(true);
          break;
      }
    }
  }, [FreeTrial30]);

  const handleTeamLinkClick = () => {
    if (window.location.pathname === "/about") {
      const section = document.getElementById("team-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigateTo("/about#team-section");
    }
  };

  const handleSignUpClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "select_content",
        content_type: "button",
        link_name: "trial-button",
        link_url: `/signup`,
        os_type: deviceCtx.os.name,
        plan_type: userContext.subscription.sources[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });

    navigateTo("/signup");
  };

  if (light) {
    return (
      <NavBarLight
        isAuthenticated={isAuthenticated}
        emailAddress={emailAddress}
        showMobileLogo={showMobileLogo}
      />
    );
  }

  return (
    <>
      <link href="https://fonts.googleapis.com" rel="preconnect" />
      <link
        href="https://fonts.gstatic.com"
        rel="preconnect"
        crossOrigin="anonymous"
      />
      {isAuthenticated && (
        <UserMenu
          isOpen={isUserMenuOpen}
          setIsOpen={setIsUserMenuOpen}
          userEmail={emailAddress}
          userIconRightCoord={{ right: userIconPositions.current?.right }}
          menuRef={userMenuRef}
        />
      )}
      <div className="h-[104px] md:h-[114px]">
        <div className="relative flex items-center justify-between p-6">
          <MobileLeftNavMenu
            isAuthenticated={isAuthenticated}
            isOpen={isMobileMenuOpen}
            closeMenu={setIsMobileMenuOpen}
            menuRef={mobileMenuRef}
          />
          <div className="flex items-center">
            <div className="-mb-1 -ml-2 mr-2 flex h-[60px] w-[56px] cursor-pointer items-center justify-center sm:mr-4 navMobile:hidden">
              <div
                role="button"
                id="mobile_nav_open"
                className="w-[30px]"
                onClick={() => setIsMobileMenuOpen(true)}
              >
                <img src={MenuIcon} alt="Menu" loading="eager" />
              </div>
            </div>
            <div className="cursor-pointer" onClick={() => navigateTo("/")}>
              <img
                src={logo}
                alt="Truplay-logo"
                className="w-[7.5rem] sm:w-[9.5rem] lg:w-48"
                id="unauth_tru_logo"
              />
            </div>
          </div>
          <div className="flex items-center gap-2 sm:gap-4">
            <div className="hidden lg:items-center navMobile:!flex">
              {/* <Button
                id="desktop_kickstarter_link"
                onClick={() => navigateTo("/support-our-mission")}
                btnType="tertiary"
                className={
                  "px-3" +
                  (window.location.pathname.indexOf("/support-our-mission") !==
                  -1
                    ? " !text-turquoise-500"
                    : "")
                }
              >
                Kickstarter
              </Button> */}
              <Button
                id="desktop_games_more_link"
                onClick={() => navigateTo("/games")}
                btnType="tertiary"
                className={
                  "px-3" +
                  (window.location.pathname.indexOf("/games") !== -1
                    ? " !text-turquoise-500"
                    : "")
                }
              >
                Games
              </Button>
              <div
                id="desktop_nav_dropdown_btn"
                ref={moreMenuRef}
                role="button"
                className="relative flex items-center justify-center"
              >
                <Button
                  id="desktop_menu_dropdown_btn"
                  onClick={() => setIsMoreMenuOpen(!isMoreMenuOpen)}
                  btnType="tertiary"
                  className={
                    "px-3" + (isMoreMenuOpen ? " !text-turquoise-500" : "")
                  }
                  rightIcon={
                    isMoreMenuOpen ? (
                      <img
                        src={ChevronRightTeal}
                        alt="caret down"
                        loading="eager"
                        className="ml-3 w-6 -rotate-90 duration-300"
                      />
                    ) : (
                      <img
                        src={ChevronRightWhite}
                        alt="caret down"
                        loading="eager"
                        className="ml-3 w-6 rotate-90 duration-300"
                      />
                    )
                  }
                >
                  More
                </Button>
                {isMoreMenuOpen && (
                  <div
                    className={
                      "tru-menu-blur absolute top-[45px] flex flex-col flex-nowrap rounded-b-2xl bg-nav_manu" +
                      " border-2 border-t-0 border-solid border-indigo-400/80 pb-2 pt-8 shadow-[0px_4px_16px_0px_rgba(0,27,54,0.7)]"
                    }
                  >
                    <div className="flex justify-center px-6 py-4">
                      <Button
                        id="give_as_gift_btn"
                        onClick={() => {
                          navigateTo("/gift");
                        }}
                        btnType="secondary"
                        className="w-[200px]"
                      >
                        Give as Gift
                      </Button>
                    </div>
                    <a
                      id="desktop_merchandise_link"
                      href="https://truplaygames.shop/"
                      rel="noreferrer"
                      target="_blank"
                      className={dropdownItemStyles}
                    >
                      Merchandise
                    </a>
                    <div
                      id="desktop_free_content_link"
                      role="button"
                      onClick={() => navigateTo("/free-content")}
                      className={
                        dropdownItemStyles +
                        (window.location.pathname.indexOf("/free-content") !==
                        -1
                          ? " pointer-events-none bg-dropdown_active"
                          : "")
                      }
                    >
                      Free Content
                    </div>
                    <div
                      id="desktop_who_we_are_link"
                      role="button"
                      onClick={() => navigateTo("/about")}
                      className={
                        dropdownItemStyles +
                        (window.location.pathname.indexOf("/about") !== -1
                          ? " pointer-events-none bg-dropdown_active"
                          : "")
                      }
                    >
                      About Us
                    </div>
                    <a
                      id="desktop_news_link"
                      href="https://www.truplaygames.com/resources/news"
                      rel="noreferrer"
                      target="_blank"
                      className={dropdownItemStyles}
                    >
                      News
                    </a>
                    <div
                      id="desktop_affiliate_link"
                      role="button"
                      onClick={() => navigateTo("/affiliate")}
                      className={
                        dropdownItemStyles +
                        (window.location.pathname === "/affiliate"
                          ? " pointer-events-none bg-dropdown_active"
                          : "")
                      }
                    >
                      Refer A Friend
                    </div>
                    <div
                      id="desktop_contact_link"
                      role="button"
                      onClick={() => navigateTo("/contact")}
                      className={
                        dropdownItemStyles +
                        (window.location.pathname.indexOf("/contact") !== -1
                          ? " pointer-events-none bg-dropdown_active"
                          : "")
                      }
                    >
                      Contact
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!isAuthenticated && (
              <>
                {window.location.pathname !== "/signup" ||
                  ("/login" && (
                    <Button
                      id="sign_up_btn"
                      btnType="green"
                      onClick={() => handleSignUpClick()}
                      className="max-h-[52px] whitespace-nowrap sm:max-h-[56px]"
                    >
                      <span className="hidden md:inline">
                        {freeTrial30
                          ? "Start 30 Day Free Trial"
                          : "Redeem Your Free Week"}
                      </span>
                      <span className="md:hidden">Free Trial</span>
                    </Button>
                  ))}
                {window.location.pathname !== "/login" && (
                  <Button
                    id="log_in_btn"
                    btnType="tertiary"
                    className="hidden lg:!inline"
                    onClick={() => {
                      navigateTo(`/login${GetContinueToQueryString()}`);
                    }}
                  >
                    Log In
                  </Button>
                )}
              </>
            )}
            {isAuthenticated && (
              <div
                id="active_user_menu_option"
                className={
                  "relative flex min-h-12 min-w-12 cursor-pointer items-center justify-center rounded-full" +
                  " bg-user_menu_icon duration-200 hover:scale-110 hover:shadow-[0_3px_20px_rgba(56,166,213,0.6)]"
                }
                onClick={() => {
                  setIsMobileMenuOpen(false);
                  setIsUserMenuOpen(true);
                }}
                ref={userIconRef}
              >
                <img
                  src={UserIcon}
                  alt="User Icon"
                  className={
                    "h-[1.1rem]" + (isUserMenuOpen ? " hidden" : " block")
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
