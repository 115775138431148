import { useContext, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";

// Components
import BenjaminCTA from "../../components/home-landing/BenjaminCTA/BenjaminCTA";
import MapleCTA from "../../components/home-landing/MapleCTA/MapleCTA";
import ValuesChecklist from "../../components/common/ValuesChecklist";
import AppReviewSlider from "../../components/common/AppReviewsSlider";
import EndorsementsLogos from "../../components/home-landing/Endorsements/EndorsementsLogos";
import VideoReviewSlider from "../../components/common/VideoReviewsSlider";
import Video from "../../components/common/Video";
import Button from "../../components/common/Button";

// Context
import {
  EnvironmentConfigContext,
  SubscriptionPeriod,
} from "../../config/Environment";
import {
  GetOrCreateSessionAuthContext,
  navigateTo,
} from "../../config/UserAuthContext";
import { useFeatureFlags } from "../../config/FeatureFlagContext";

// Packages
import { useIsVisible } from "../../hooks/useIsVisible";
import { handleAutoPlay } from "../../lib/HelperFunctions";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import { useVideoManagement } from "../../hooks/useVideoManagement";
import { useAnalytics } from "../../hooks/useAnalytics";

// Images
import accentStars1 from "../../global-assets/assets/Accent_Stars_01.svg";
import accentStars2 from "../../global-assets/assets/Accent_Stars_02.svg";
import BibleGames from "../../global-assets/assets/platform-screenshot-bible-games.webp";
import MultipleProfiles from "../../global-assets/assets/platform-screenshot-multiple-profiles.webp";
import WorldOfRhym from "../../global-assets/assets/platform-screenshot-world-of-rhym-alt.webp";
import FunForAll from "../../global-assets/assets/platform-screenshot-fun-for-all.webp";
import BiblicalLessons from "../../global-assets/assets/platform-screenshot-biblical-lessons-alt.webp";
import ComicsVideos from "../../global-assets/assets/platform-screenshot-comics-videos.webp";
import MapleImg from "../../global-assets/assets/cta-maple.png";
import HuckabeeLogo from "../../global-assets/assets/logo-huckabee-alt.png";
import CandacePodcastLogo from "../../global-assets/assets/logo-candace-bure-podcast.webp";
import ChristianityTodayLogo from "../../global-assets/assets/logo-christianity-today.png";
import PromiseKeepersLogo from "../../global-assets/assets/logo-promise-keepers.png";
import BillyGrahamLogo from "../../global-assets/assets/logo-billy-graham.svg";
import PluggedInLogo from "../../global-assets/assets/logo-plugged-in.webp";
// import MediShareLogo from "../../global-assets/assets/logo-medi-share.png";
import TruPlayLogoShadow from "../../global-assets/assets/truplay-logo-large-shadow.webp";
import HeroLoopPlaceholder from "../../global-assets/assets/bub-hero-loop-bg.webp";
import ThumbTwoBoys from "./assets/thumbnail_2_boys_talking_about_ben.webp";
import ThumbExcitedMom1 from "./assets/thumbnail_excited_mom_part_1.webp";
import ThumbExcitedMom2 from "./assets/thumbnail_excited_mom_part_2.webp";
import ThumbFamilyInCar from "./assets/thumbnail_family_in_car.webp";
import ThumbGirlPlayingMaple from "./assets/thumbnail_girl_playing_maple.webp";
import ThumbLoveForGod from "./assets/thumbnail_love_for_god.webp";
import ThumbMomsApp from "./assets/thumbnail_moms_new_favorite_app.webp";
import ThumbMomTwoBoys from "./assets/thumbnail_mom_with_two_boys.webp";
import ThumbMomTwoKids from "./assets/thumbnail_mom_with_two_kids.webp";
import ThumbOliverFavorite from "./assets/thumbnail_oliver_and_the_vindictive_vines_is_his_favorite_truplay_game.webp";
import ThumbTwoGirls from "./assets/thumbnail_2_girls_playing_on_ipad.webp";
import ThumbStickerPack from "./assets/thumbnail_sticker_pack.webp";
import ThumbTestimonial from "./assets/thumbnail_truplay_testimonial.webp";
import ThumbKimWalker from "./assets/thumbnail_Kim_Walker.webp";
import ThumbDemo from "./assets/thumbnail_homepage_demo.webp";
import ThumbHeroHuckabee from "./assets/thumbnail_hero_huckabee.webp";
import ThumbInspireMe from "./assets/thumbnail_inspre_me.png";
import PriceOptions from "../../components/common/PriceOptions";
import BlurredSection from "../../components/common/BlurredSection";

const checkmarkPoints = [
  "One app, multiple high-quality Christian games.",
  "New content added all the time.",
  "Positive Biblical values in every piece of content.",
  "One subscription: no ads, chatrooms, or in-app purchases.",
];

const checkmarkPointsAlt = [
  "New high-quality games added all the time.",
  "Positive Biblical values in every piece of content.",
  "One subscription: no ads, chatrooms, or in-app purchases.",
];

const endorsedLogos = {
  christianityToday: { id: "christianityToday", image: ChristianityTodayLogo },
  candace: { id: "candace", image: CandacePodcastLogo },
  billy: { id: "billy", image: BillyGrahamLogo },
  pk: { id: "pk", image: PromiseKeepersLogo },
  pluggedIn: { id: "pluggedIn", image: PluggedInLogo },
  huckabee: { id: "huckabee", image: HuckabeeLogo },
};

interface VideoInfo {
  [key: string]: {
    id: number;
    url: string;
    title: string;
    autoPlay?: boolean;
    visible: boolean;
    loop?: boolean; // Default is false
    muted?: boolean; // Default is false
    playsinline?: boolean; // Default is true
    shareLink?: string;
    thumbnail?: string;
  };
}

const videoDefaults = {
  ipadVideo: {
    id: 946337337,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/homepage_demo%20(720p).mp4",
    title: "Discover TruPlay Games",
    autoPlay: false,
    visible: false,
    loop: true,
    muted: true,
    shareLink: "https://vimeo.com/946337337",
    thumbnail: ThumbDemo,
  },
  heroHuckabeeVideo: {
    id: 945854154,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/hero-bullet-points-huckabee.mp4",
    title: "Discover TruPlay Games",
    visible: false,
    shareLink: "https://vimeo.com/945854154",
    thumbnail: ThumbHeroHuckabee,
  },
};

interface VideoReview {
  idx: number;
  url: string;
  width: string | number;
  height: string | number;
  component: JSX.Element | null;
  visible: boolean;
  focus: boolean;
  title: string;
  thumbnail: string;
  shareLink: string;
  videoId: string | number;
}

const videoReviews: VideoReview[] = [
  {
    idx: 1,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/love_for_god_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 1",
    thumbnail: ThumbLoveForGod,
    shareLink: "https://vimeo.com/943820182",
    videoId: 943820182,
  },
  {
    idx: 2,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/mom_with_two_kids_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 2",
    thumbnail: ThumbMomTwoKids,
    shareLink: "https://vimeo.com/935498545",
    videoId: 935498545,
  },
  {
    idx: 3,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/excited_mom_part_1_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 3",
    thumbnail: ThumbExcitedMom2,
    shareLink: "https://vimeo.com/935518118",
    videoId: 935518118,
  },
  {
    idx: 4,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/mom's_new_favorite_app_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 4",
    thumbnail: ThumbMomsApp,
    shareLink: "https://vimeo.com/943824997",
    videoId: 943824997,
  },
  {
    idx: 5,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/truplay_testimonial_-_pov%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 5",
    thumbnail: ThumbTestimonial,
    shareLink: "https://vimeo.com/935509797",
    videoId: 935509797,
  },
  {
    idx: 6,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/excited_mom_part_2_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 6",
    thumbnail: ThumbExcitedMom1,
    shareLink: "https://vimeo.com/935512425",
    videoId: 935512425,
  },
  {
    idx: 7,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/the_best_app_for_kids_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 7",
    thumbnail: ThumbTwoGirls,
    shareLink: "https://vimeo.com/969178390",
    videoId: 969178390,
  },
  {
    idx: 8,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/Kim%20Walker%20Smith%20talking%20about%20TruPlay_3.mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 8",
    thumbnail: ThumbKimWalker,
    shareLink: "https://vimeo.com/1002850401",
    videoId: 1002850401,
  },
  {
    idx: 9,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/cic-inspire-me.mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 9",
    thumbnail: ThumbInspireMe,
    shareLink: "https://vimeo.com/1008922595",
    videoId: 1008922595,
  },
  {
    idx: 10,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/oliver_and_the_vindictive_vines_is_his_favorite_truplay_game_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 10",
    thumbnail: ThumbOliverFavorite,
    shareLink: "https://vimeo.com/945866477",
    videoId: 945866477,
  },
  {
    idx: 11,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/2_boys_talking_about_ben_-_truplay_testimonial%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 11",
    thumbnail: ThumbTwoBoys,
    shareLink: "https://vimeo.com/935492076",
    videoId: 935492076,
  },
  {
    idx: 12,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/family_in_car_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 12",
    thumbnail: ThumbFamilyInCar,
    shareLink: "https://vimeo.com/943822843",
    videoId: 943822843,
  },
  {
    idx: 13,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/sticker_pack_-_truplay_testimonials%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 13",
    thumbnail: ThumbStickerPack,
    shareLink: "https://vimeo.com/935507967",
    videoId: 935507967,
  },
  {
    idx: 14,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/girl_playing_maple_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 14",
    thumbnail: ThumbGirlPlayingMaple,
    shareLink: "https://vimeo.com/943826769",
    videoId: 943826769,
  },
  {
    idx: 15,
    url: "https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/mom_with_two_boys_-_truplay_ugc%20(720p).mp4",
    width: "100%",
    height: "100%",
    component: null,
    visible: false,
    focus: false,
    title: "TruPlay Testimony 15",
    thumbnail: ThumbMomTwoBoys,
    shareLink: "https://vimeo.com/935503763",
    videoId: 935503763,
  },
];

export default function HomeLanding() {
  const analytics = useAnalytics();
  const initialUserContext = GetOrCreateSessionAuthContext();
  const config = useContext(EnvironmentConfigContext);

  const monthlyPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Monthly
    ) || 0;
  const annualPrice: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.Annual
    ) || 0;
  const annualDiscountPrice6999: number =
    config?.productConfig.subscriptionProductPrice.get(
      SubscriptionPeriod.AnnualDiscounted6999
    ) || 0;
  const annualMonthlyPrice = (annualPrice / 12).toFixed(2);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoToShow: string | null = params.get("video");

  const {
    currentAutoPlayVideos,
    setCurrentAutoPlayVideos,
    shiftedVideos,
    handleCenterOnVideo,
  } = useVideoManagement(videoDefaults, videoReviews, videoToShow);

  const carouselRef = useRef(null);
  const ipadVideoRef = useRef<HTMLDivElement>(null);
  const isIpadVideoVisible = useIsVisible(ipadVideoRef, 1.0);
  const [mobileView, setMobileView] = useState<boolean>(false);

  const osType = navigator.userAgent;
  const GlobalUserId = Cookies.get("GlobalUserId");

  useEffect(() => {
    analytics.trackEvent("page_view", {
      page_name: "home",
      os_type: osType,
      plan_type:
        initialUserContext.subscription.sources[0]?.productId ?? "none",
      custom_user_id: GlobalUserId || undefined,
    });
  }, []);

  const onCTAClick = (linkType: string, buttonId: string) => {
    analytics.trackEvent("select_content", {
      content_type: linkType,
      button_id: buttonId,
      os_type: osType,
      plan_type:
        initialUserContext.subscription.sources[0]?.productId ?? "none",
      custom_user_id: GlobalUserId || undefined,
    });
    navigateTo(`/${linkType}`);
  };

  const handleVideoClick = (videoId: string, videoTitle: string) => {
    analytics.trackEvent("select_content", {
      content_type: "video",
      video_id: videoId,
      video_title: videoTitle,
      os_type: osType,
      plan_type:
        initialUserContext.subscription.sources[0]?.productId ?? "none",
      custom_user_id: GlobalUserId || undefined,
    });
  };

  // Add a video to the visibleVidoes object to enable autoplay once a ref
  // and isVisible check has been created for it
  useEffect(() => {
    const visibleVideos = {
      ipadVideo: isIpadVideoVisible,
    };
    handleAutoPlay(
      visibleVideos,
      currentAutoPlayVideos,
      setCurrentAutoPlayVideos
    );

    const videoToPlay: any = document.getElementById(
      videoDefaults.ipadVideo.id.toString() + "_mobile" || ""
    );
    if (videoToPlay) {
      if (currentAutoPlayVideos?.ipadVideo.autoPlay) {
        videoToPlay.play();
      } else {
        videoToPlay.pause();
      }
    }

    // Missing deps will causes infinite re-render, ignoring deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIpadVideoVisible]);

  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div id="test_data_landing_page" className="min-h-screen">
      <Helmet>
        <title>
          Home | Truplay Games - Safe, Biblical, Fun & Educational Games for
          Kids
        </title>
        <meta
          name="description"
          content="Discover fun and educational mobile games for kids aged 4-15. Sign up for a subscription today!"
        />
        <link rel="canonical" href="https://www.truplaygames.com/" />
      </Helmet>
      <div className="relative overflow-hidden">
        <div className="tru-home-purple-bg3 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-20 lg:opacity-40" />
        <div className="tru-home-green-bg3 absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-15 lg:opacity-30" />
        <div className="tru-home-blue-bg-video absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-20 lg:opacity-40" />
        <div className="tru-home-blue-bg2 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-purple-bg4 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-blue-bg-iphones absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-20 lg:opacity-40" />
        <div className="tru-home-green-bg4 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-10 lg:opacity-20" />
        <div className="tru-home-blue-bg3 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-purple-bg5 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="relative overflow-hidden">
          <div className="relative">
            <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center">
              <div className="relative mx-auto flex w-full flex-col gap-6">
                <div className="flex min-h-[600px] flex-col items-center justify-center gap-6 px-5 py-12 pt-24 sm:min-h-[750px] sm:px-6 sm:py-16 md:min-h-[900px] md:px-7 md:py-24 lg:px-12 lg:py-32">
                  <div className="flex flex-col items-center gap-5">
                    <img
                      src={TruPlayLogoShadow}
                      loading="eager"
                      alt="TruPlay Games"
                      className="w-96"
                    />
                    <Button
                      id="home_hero_cta_btn"
                      onClick={() =>
                        onCTAClick("user-registration", "trial_button")
                      }
                      btnType="green"
                      className="w-full"
                    >
                      Redeem Your Free Week
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tru-hero-mask-transition absolute bottom-0 left-0 right-0 top-0 -z-[1]">
              <video
                autoPlay
                loop
                muted
                playsInline
                poster={HeroLoopPlaceholder}
              >
                <source src="https://truplay-videos.sfo3.cdn.digitaloceanspaces.com/2024-05-30_WEB_Hero.mp4" />
              </video>
            </div>
          </div>
          <div className="relative -mt-[90px] overflow-hidden px-5 py-12 sm:px-6 sm:py-16 md:-mt-[150px] md:px-7 md:py-24 lg:-mt-[210px] lg:px-12 lg:py-32">
            <div className="mx-auto max-w-[1440px]">
              <div className="flex flex-col gap-12">
                <h1 className="text-transparent relative mx-auto max-w-[300px] bg-gradient_text bg-clip-text text-center drop-shadow-[0px_4px_4px_rgba(0,0,0,0.35),0px_0px_48px_rgba(0,0,0,0.35)] sm:max-w-[360px] md:max-w-[400px] lg:max-w-none">
                  <img
                    className="invisible absolute -left-[70px] -top-[20px] lg:visible"
                    src={accentStars1}
                    loading="lazy"
                    alt=""
                  />
                  Turn Game Time Into God Time
                </h1>
                <div className="relative mx-auto flex w-full flex-col items-center gap-12 lg:flex-row ">
                  <div className="w-full flex-1">
                    <Video
                      id={videoDefaults.heroHuckabeeVideo.id.toString()}
                      videoId={videoDefaults.heroHuckabeeVideo.id.toString()}
                      url={videoDefaults.heroHuckabeeVideo.url}
                      shareUrl={videoDefaults.heroHuckabeeVideo.shareLink}
                      playsInline
                      thumbnail={videoDefaults.heroHuckabeeVideo.thumbnail}
                      controls
                      shareSmall
                    />
                  </div>
                  <div className="flex w-full flex-1 flex-col justify-center gap-10">
                    <h2 className="hidden text-center text-white md:block lg:text-left">
                      One App, Multiple Games
                    </h2>
                    <div className="flex flex-col items-center justify-center lg:items-start lg:justify-start">
                      <ValuesChecklist points={checkmarkPointsAlt} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative mx-auto flex w-full flex-col gap-8 px-5 py-12 pt-6 text-center sm:px-6 sm:py-16 md:px-7 md:py-24 lg:gap-20 lg:px-12 lg:py-32 lg:pt-0">
          <div className="mx-auto flex max-w-[1440px] flex-col items-center gap-y-16 md:gap-y-20 lg:gap-24">
            <div className="flex flex-col items-center gap-4 text-center">
              <h2 className="text-transparent relative bg-gradient_text bg-clip-text lg:max-w-[790px] xl:max-w-none">
                Games Kids Love <br className="lg:hidden" />& Parents Can Trust
                <img
                  className="absolute -left-[20px] -top-[20px] hidden lg:block xl:-left-[70px]"
                  src={accentStars1}
                  loading="lazy"
                  alt=""
                />
              </h2>
              <h1 className="mx-auto font-body text-base font-normal leading-normal text-white lg:text-[2rem]">
                Explore our collection of Christian Games and Bible-Based
                Content.
              </h1>
            </div>
            <div
              ref={ipadVideoRef}
              className="mx-auto w-full max-w-[1000px] flex-1"
            >
              <Video
                id={
                  videoDefaults.ipadVideo.id.toString() +
                  (mobileView ? "_mobile" : "")
                }
                videoId={videoDefaults.ipadVideo.id.toString()}
                url={videoDefaults.ipadVideo.url}
                shareUrl={videoDefaults.ipadVideo.shareLink}
                playsInline
                thumbnail={ThumbDemo}
                iPadView
                borderColor="bg-black"
                controls={mobileView ? false : true}
                loop={mobileView ? true : false}
                muted={mobileView ? true : false}
              />
            </div>
            <div className="flex w-full flex-col gap-4 lg:flex-row lg:gap-12">
              <div className="flex flex-1 flex-col items-center gap-4 rounded-[40px] bg-[rgba(255,255,255,0.06)] p-8 shadow-[0px_0px_80px_0px_#001b36]">
                <h2 className="text-[2.75rem] leading-normal text-white">
                  100+
                </h2>
                <p className="text-xl font-normal !leading-normal text-indigo-200 md:text-3xl lg:text-[2rem]">
                  Hours of content and growing
                </p>
              </div>
              <div className="flex flex-1 flex-col items-center gap-4 rounded-[40px] bg-[rgba(255,255,255,0.06)] p-8 shadow-[0px_0px_80px_0px_#001b36]">
                <h2 className="text-[2.75rem] leading-normal text-white">
                  35+
                </h2>
                <p className="text-xl font-normal !leading-normal text-indigo-200 md:text-3xl lg:text-[2rem]">
                  Games, comics and videos all in one app
                </p>
              </div>
              <div className="flex flex-1 flex-col items-center gap-4 rounded-[40px] bg-[rgba(255,255,255,0.06)] p-8 shadow-[0px_0px_80px_0px_#001b36]">
                <h2 className="text-[2.75rem] leading-normal text-white">
                  Ages 4-12
                </h2>
                <p className="text-xl font-normal !leading-normal text-indigo-200 md:text-3xl lg:text-[2rem]">
                  Biblical lessons tailored for your children
                </p>
              </div>
            </div>
          </div>
          <div className="relative mx-auto flex w-full flex-col items-center gap-8 text-center md:gap-12 lg:gap-16">
            <div className="flex flex-col gap-4">
              <h2 className="text-transparent relative mx-auto max-w-[700px] bg-gradient_text bg-clip-text lg:max-w-none">
                Your Subscription Includes
                <img
                  className="absolute -right-[95px] -top-[40px] left-auto hidden lg:block"
                  src={accentStars2}
                  loading="lazy"
                  alt=""
                />
              </h2>
              <p className="max-w-[1000px] text-white lg:text-[2rem] lg:font-normal lg:leading-normal">
                A RhymVerse of character and faith-building adventures featuring
                dozens of games, animated shorts, and comics!
              </p>
            </div>
            <div className="grid grid-cols-1 gap-y-10 homemd:grid-cols-2 homemd:gap-x-10 lg:grid-cols-3 lg:gap-y-20">
              <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
                <img src={BibleGames} alt="" loading="lazy" />
                <div className="flex flex-col gap-4">
                  <h4 className="text-white">Bible Games</h4>
                  <p className="text-base text-indigo-200">
                    Biblical scripture and parable lessons are organically
                    integrated into game play.
                  </p>
                </div>
              </div>
              <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
                <img src={WorldOfRhym} alt="" loading="lazy" />
                <div className="flex flex-col gap-4">
                  <h4 className="text-white">The World of Rhym</h4>
                  <p className="text-base text-indigo-200">
                    Games take place in an original universe built to help
                    little ones grow in character and faith.
                  </p>
                </div>
              </div>
              <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
                <img src={ComicsVideos} alt="" loading="lazy" />
                <div className="flex flex-col gap-4">
                  <h4 className="text-white">Comics & Videos</h4>
                  <p className="text-base text-indigo-200">
                    Featuring stories from Book of God, The Action Bible, and
                    high quality originals.
                  </p>
                </div>
              </div>
              <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
                <img src={BiblicalLessons} alt="" loading="lazy" />
                <div className="flex flex-col gap-4">
                  <h4 className="text-white">Biblical Lessons</h4>
                  <p className="text-base text-indigo-200">
                    Every game and video on TruPlay is inspired by the lessons
                    and stories in the Bible.
                  </p>
                </div>
              </div>
              <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
                <img src={MultipleProfiles} alt="" loading="lazy" />
                <div className="flex flex-col gap-4">
                  <h4 className="text-white">Multiple Profiles</h4>
                  <p className="text-base text-indigo-200">
                    Download the TruPlay app on up to 10 users at no additional
                    charge.
                  </p>
                </div>
              </div>
              <div className="flex max-w-[285px] flex-col gap-4 sm:max-w-[315px]">
                <img src={FunForAll} alt="" loading="lazy" />
                <div className="flex flex-col gap-4">
                  <h4 className="text-white">Fun for All</h4>
                  <p className="text-base text-indigo-200">
                    A wide range of games and activities for kids (and parents)
                    to enjoy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BlurredSection fullWidth>
          <div className="tru-home-purple-bg2 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
          <div className="tru-home-green-bg2 absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-15 lg:opacity-30" />
          <div className="mx-auto max-w-[1440px]">
            <div className="relative mx-auto flex w-full flex-col items-center gap-6 text-center">
              <h2 className="text-transparent relative mb-8 max-w-[268px] bg-gradient_text bg-clip-text sm:max-w-[300px] md:max-w-none">
                Save with an Annual Subscription
                <img
                  className="absolute -right-[90px] -top-[40px] hidden lg:block"
                  src={accentStars2}
                  loading="lazy"
                  alt=""
                />
              </h2>
              <PriceOptions
                annualPrice={annualDiscountPrice6999}
                monthlyPrice={monthlyPrice}
              />
            </div>
          </div>
        </BlurredSection>
      </div>
      <div className="relative overflow-hidden px-5 py-12 sm:px-6 sm:py-16 md:px-7 md:py-24 lg:px-12 lg:py-32">
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center">
          <BenjaminCTA
            title="Ready to play?"
            mobileTitle="Ready to play?"
            buttonText={"Redeem Your Free Week"}
            mobileButtonText={"Redeem Your Free Week"}
            ctaAction="/signup"
          />
        </div>
      </div>
      <div className="relative overflow-hidden bg-white/5 py-12 shadow-[0_0_80px_0_#001b36] sm:py-16 md:py-24 lg:py-32">
        <div className="tru-home-purple-bg6 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-15 lg:opacity-30" />
        <div className="tru-home-green-bg5 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-10 lg:opacity-20" />
        <div className="tru-home-blue-bg4 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-20 lg:opacity-40" />
        <div className="absolute bottom-0 left-0 right-auto top-0 z-[1] hidden w-[100px] bg-[linear-gradient(to_right,#001b36be,transparent_70%)] lg:inline" />
        <div className="absolute bottom-0 left-auto right-0 top-0 z-[1] hidden w-[100px] bg-[linear-gradient(to_left,#001b36be,transparent_70%)] lg:inline" />
        <div className="mx-auto max-w-[1440px]">
          <div className="relative mx-auto my-0 mb-8 flex w-full flex-col gap-6">
            <h2 className="text-transparent relative mx-auto bg-gradient_text bg-clip-text text-center">
              What Our Customers Say
              <img
                src={accentStars1}
                loading="lazy"
                alt=""
                className="absolute -left-[70px] -top-[20px] hidden lg:block"
              />
            </h2>
            <p className="mx-auto my-0 max-w-[900px] text-center text-white lg:text-[2rem] lg:font-normal lg:leading-normal">
              See what parents are saying about the all new Christian
              entertainment platform.
            </p>
          </div>
        </div>
        <div ref={carouselRef} className="flex flex-col gap-16">
          <VideoReviewSlider
            videos={shiftedVideos ? shiftedVideos : videoReviews}
            noTitle
          />
          <AppReviewSlider lightBackground noTitle />
        </div>
      </div>
      <div className="relative overflow-hidden px-5 py-12 sm:px-6 sm:py-16 md:px-7 md:py-24 lg:px-12 lg:py-32">
        <div className="tru-home-blue-bg5 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-25 lg:opacity-50" />
        <div className="tru-home-green-bg6 absolute bottom-0 left-0 right-0 top-0 -z-[2] opacity-20 lg:opacity-40" />
        <div className="tru-home-purple-bg7 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-25 lg:opacity-50" />
        <div className="tru-home-blue-bg6 absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-30 lg:opacity-30" />
        <div className="max-w-[2000px mx-auto lg:pb-[125px]">
          <EndorsementsLogos
            title="TruPlay is Endorsed by:"
            mobileTitle="Endorsed by:"
            noBackground
            logos={endorsedLogos}
          />
        </div>
        <div className="mx-auto flex max-w-[1440px] flex-col items-center justify-center gap-x-4 gap-y-[60px] md:gap-y-[75px] lg:gap-y-[150px]">
          <div className="relative mx-auto my-0 hidden w-full flex-col gap-6 lg:flex">
            <MapleCTA
              image={MapleImg}
              title="Start Playing in the Light"
              mobileTitle=""
              buttonText={"Redeem Your Free Week"}
              mobileButtonText=""
              ctaAction="/signup"
            />
          </div>
          <div className="relative mx-auto flex w-full flex-col gap-6 text-center">
            <h2 className="text-transparent relative mx-auto bg-gradient_text bg-clip-text">
              Have Questions?
              <img
                src={accentStars1}
                loading="lazy"
                alt=""
                className="absolute -left-[70px] -top-[20px] hidden lg:block"
              />
            </h2>
            <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div
                className="flex flex-1 cursor-pointer flex-col items-center gap-4 rounded-3xl bg-[rgba(255,255,255,0.06)] p-8 shadow-[0_25px_20px_-20px_#001b36] duration-100 hover:-translate-y-2 hover:shadow-[0px_4px_30px_5px_rgba(56,166,213,0.6)]"
                onClick={() => onCTAClick("faq", "faq_button")}
              >
                <h4 className="text-white">
                  Visit our{" "}
                  <span className="text-cerulean-500 hover:underline">FAQ</span>{" "}
                  page
                </h4>
              </div>
              <div
                className="flex flex-1 cursor-pointer flex-col items-center gap-4 rounded-3xl bg-[rgba(255,255,255,0.06)] p-8 shadow-[0_25px_20px_-20px_#001b36] duration-100 hover:-translate-y-2 hover:shadow-[0px_4px_30px_5px_rgba(56,166,213,0.6)]"
                onClick={() => onCTAClick("contact", "contact_button")}
              >
                <h4 className="text-white">
                  Need help?{" "}
                  <span className="text-cerulean-500 hover:underline">
                    Contact Us
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
