import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AboutModal from "../AboutModal/AboutModal";
import Button from "../../common/Button";

// Assets //
import topcurvewhite from "../../../global-assets/assets/topcurvewhite.svg";
import teambg from "../../../global-assets/assets/teambg.webp";
import BrentDusing from "./assets/headshot-brent-dusing.png";
import JeffAllen from "./assets/headshot-jeff-allen.png";
import JenniJoseph from "./assets/headshot-jenni-joeseph.jpg";
import HerveGomez from "./assets/headshot-herve-gomez.jpg";
import TemitopeAjagbe from "./assets/headshot-temitope-ajagbe.jpg";
import AliceMills from "./assets/headshot-alice-mills.jpg";
import KaraBoyce from "./assets/headshot-kara-boyce.jpg";

interface TeamMember {
  name: string;
  role: string;
  imageSrc: string;
  bio: string;
}

export const TeamData = [
  {
    name: "Brent Dusing",
    role: "CEO & Founder",
    imageSrc: BrentDusing,
    bio: "Brent Dusing created TruPlay to bring excellent, fun, beautiful, and biblically authentic entertainment to audiences worldwide. An entrepreneur at heart, Brent pioneered game creation with Christian content through Lightside Games, a Christian gaming studio reaching more than 7 million game players world wide and resulting in 25,000 decisions for Christ. As the founder and CEO of Cellfire, Brent created the nation's leading mobile coupon company, used today at grocers like Safeway and Kroger, leading Catalina Marketing to acquire Cellfire for $108M. Brent began his career as a venture capitalist at Menlo Ventures, where he sourced multiple 9-figure exits and generated top-quartile returns. Over his career, Brent has been featured on CNN, Fox News, ABC News, The Wall Street Journal, New York Times, USA Today, Entertainment Weekly, and other news media. Brent has a bachelor's degree in economics from Harvard University.",
  },
  {
    name: "Jenni Joseph",
    role: "Chief of Staff",
    imageSrc: JenniJoseph,
    bio: "Jenni Joseph is passionate about sharing God's good news of salvation through Jesus Christ. She believes in the importance of wholesome outlets and activities that provide enriching and family-oriented fun with the potential to create exciting dialogues about God and His Word. Before coming to TruPlay, Jenni served as the Executive Director of Lightside, a Christian gaming studio that reached more than 1 million subscribers worldwide. She also oversaw community, QA, and Operations in her time at Lightside. She was instrumental in bridging the desires of Lightside's community and families into faith-based products by way of design, quality assurance, biblical veracity, and product creativity. As a Christian, her central focus is implementing TruPlay's mission and values by way of high-quality, biblically authentic Christian entertainment that can be enjoyed by all ages and the entire family.",
  },
  {
    name: "Jeff Allen",
    role: "Head of Marketing",
    imageSrc: JeffAllen,
    bio: `Inspired by the mission to deliver high-quality games and entertainment that promote positive values to our kids, Jeff joined TruPlay with 15 years of experience in strategic marketing leadership. Throughout his career, he has consistently delivered winning strategies and hockey stick style growth to several direct-to-consumer, subscription-based businesses including Hallmark Movies Now, Hallmark eCards, Tru Niagen, and ShortsTV.  Jeff is a devout Catholic and devoted family man. He is a versatile, mission-driven, big-picture thinker who is always ready to pivot to support the changing needs of customers, audiences, and stakeholders.`,
  },
  {
    name: "Temitope Ajagbe",
    role: "Director of Engineering",
    imageSrc: TemitopeAjagbe,
    bio: "With over 20 years of experience in the technology sector, Temi brings a tremendous amount of value to the TruPlay team. His most notable work includes being a key contributor to the Amazon Shipping Algorithm during his time at Amazon Web Services as well as holding a Technical Solutions Engineering role at Google. Temi's experience consists of being a Technology Instructor, Project Manager, Systems Analyst, Systems Developer, Solutions Engineer, Systems Design Consultant, and Production Engineer. He has worked at USAA, State Farm, Robert Half Technology, First Bank of Nigeria, Amazon Web Services, Facebook, and Google in various capacities. He holds a B.S in Computer Science from the University of Lagos, an M.S in Computer Science from the University of Texas (San Antonio), and currently working on Ph.D. in Management from Walden University specializing in Information Management Systems. He earned a professional Computer Security Certificate from Stanford University. He is a certified AWS Solutions Architect and Systems Security Certified Practitioner with (ISC2).",
  },
  {
    name: "Herve Gomez",
    role: "Creative Director",
    imageSrc: HerveGomez,
    bio: "Herve is a product executive with more than 15 years of experience in successfully delivering engaging interactive content to audiences worldwide, including the United States, China, India, and France. Before signing on to TruPlay's mission and vision, Herve was Vice President of Game Development (and, earlier, Director, Game Development) at Square Panda. His experience also includes Senior Producer and Product Owner at LeapFrog, where he introduced SCRUM Backlog Grooming and Road Map definition to the Development and Executive team. Previous to that, as Producer, he led the design and production of 2 best-seller launch titles for the LeapTV console (Dance and Learn Sports) and the first open sandbox game in the library (Doodle Craft). He also managed the development of licensed titles with Nickelodeon (Blaze and the Monster Machines) and Disney Pixar (Finding Dory). Herve earned a Master's Degree in Cultural Anthropology from the prestigious Université Paris Nanterre.",
  },
  {
    name: "Alice Mills",
    role: "Director of Narrative",
    imageSrc: AliceMills,
    bio: "Alice oversees the Narrative Team at TruPlay. She is also a Christian blogger, with more than 1.8 million unique visitors to her website. In addition to contributing to multiple online Christian publications, Alice regularly publishes non-fiction, fiction, and poetry in various literary journals, including children's fiction. She has also been a regular contributor to Women's Frontline Magazine. Her personal goal is to create a safe atmosphere within Christian gaming, which normalizes a childlike faith and a vibrant spirituality that adheres to an authentic Biblical worldview. Alice received an MFA in Creative Writing from the University of California, Irvine-a program that ranks among the top creative writing programs in the nation.",
  },
  {
    name: "Kara Boyce",
    role: "Operations Manager",
    imageSrc: KaraBoyce,
    bio: "An effective Executive Coordinator for TruPlay, Kara is known for her work in bridging the consumer gap and enhancing team organization. In her role as an Executive Assistant at Lightside, she provided executive management support, research, and helped streamlined business operations. Kara also acted in the role of Quality Assurance Analyst, where she oversaw an impressive user base of 7M+ players and contributed to 25K+ decisions for Christ. As a multi-platform tester for Facebook, iOS, and Android, she expertly navigated across platforms to ensure seamless user experiences. She also held a position in Customer Service at Lightside, where she effectively engaged with the largest Christian audience on Facebook, further testament to her outstanding customer service skills.",
  },
];

export default function AboutTeam() {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);

  const toggleModal = (member: TeamMember) => {
    setSelectedMember(member);
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    if (window.location.hash.indexOf("team-section") !== -1) {
      const section = document.getElementById("team-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div
      className="relative overflow-hidden px-5 py-12 sm:px-6 sm:pb-24 md:px-7 md:pb-32 lg:px-12"
      id="team-section"
    >
      <div className="tru-hiring-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-70" />
      <div className="mx-auto max-w-[1440px]">
        <div className="mx-auto mb-8 flex max-w-[820px] flex-col items-center justify-center text-center md:mb-12 lg:mb-20">
          <div className="mb-4">
            <h2 className="text-cerulean-500">Meet Our Team</h2>
          </div>
          <div className="max-w-[1040px]">
            <p className="mb-0 text-white">
              We built a world-class team from top global brands to create the
              next generation of high-quality Christian content.
            </p>
          </div>
        </div>
        <div className="mb-24">
          <div
            role="list"
            className="mx-auto grid max-w-[1140px] grid-cols-1 content-stretch justify-stretch gap-x-8 gap-y-12 sm:grid-cols-2 md:gap-x-8 lg:grid-cols-3"
          >
            {TeamData.map((member) => (
              <div key={member.name} role="listitem">
                <div className="flex flex-col content-center items-center text-center">
                  <div className="mb-4 rounded-[40px] bg-[rgba(255,255,255,0.1)] p-4 shadow-[0_0_75px_rgba(0,0,0,0.2)]">
                    <div className="overflow-hidden rounded-[30px]">
                      <img
                        src={member.imageSrc}
                        loading="lazy"
                        alt={member.name}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      <h4 className="text-transparent bg-gradient_text bg-clip-text">
                        {member.name}
                      </h4>
                    </div>
                    <div className="mb-8 font-display text-base font-bold uppercase tracking-widest text-white">
                      <div>{member.role}</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <Button
                      id={`about_member_${member.name
                        .toLowerCase()
                        .replace(" ", "_")}`}
                      onClick={() => toggleModal(member)}
                      btnType="secondary"
                      className="!border-white !px-11"
                    >
                      About
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            {isModalVisible && selectedMember && (
              <AboutModal
                isOpen={isModalVisible}
                setIsOpen={setIsModalVisible}
                selectedMember={selectedMember}
              />
            )}
          </div>
        </div>
        <div className="relative">
          <div className="mx-auto flex max-w-[820px] flex-col items-center justify-center text-center">
            <div className="mb-4">
              <h2 className="text-white">We're Hiring</h2>
            </div>
            <div className="mb-6 max-w-[1040px]">
              <p className="mb-0 text-white">
                We don't make Christian games merely because we can, or because
                we want to; we make them because we need to. We're looking for
                warriors with the same drive and passion.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start">
              <Link
                to="https://truplaygames.bamboohr.com/careers"
                target="_blank"
              >
                <Button
                  id="current_openings_btn"
                  btnType="blue"
                  className="!px-12"
                >
                  Current Openings
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <img
        src={topcurvewhite}
        loading="lazy"
        alt=""
        className="absolute bottom-0 left-0 right-0 top-auto z-[5] -mb-[1px] w-full max-w-none"
      />
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[3]">
        <div className="tru-team-section-bg-overlay absolute bottom-0 left-0 right-0 top-0 -z-[1]" />
        <div className="absolute bottom-0 left-0 right-0 top-auto -z-[2]">
          <div className="tru-team-section-bg-image-overlay absolute bottom-0 left-0 right-0 top-0"></div>
          <img
            src={teambg}
            loading="lazy"
            sizes="100vw"
            alt=""
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}
