export default function BackgroundGradientMain() {
  return (
    <>
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-[1] min-h-screen bg-white regmd:hidden" />
      <div className="tru-register-flow-purple-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-50 regmd:block" />
      <div className="tru-register-flow-blue-bg absolute bottom-0 left-0 right-0 top-0 -z-[2] hidden opacity-40 regmd:block" />
      <div className="tru-register-flow-middle-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] hidden opacity-50 regmd:block" />
    </>
  );
}
