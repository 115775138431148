import { useState, useEffect } from "react";

interface VideoInfo {
  [key: string]: {
    id: number;
    url: string;
    title: string;
    autoPlay?: boolean;
    visible: boolean;
    loop?: boolean;
    muted?: boolean;
    playsinline?: boolean;
    shareLink?: string;
    thumbnail?: string;
  };
}

interface VideoReview {
  idx: number;
  url: string;
  width: string | number;
  height: string | number;
  component: JSX.Element | null;
  visible: boolean;
  focus: boolean;
  title: string;
  thumbnail: string;
  shareLink: string;
  videoId: string | number;
}

export const useVideoManagement = (
  videoDefaults: VideoInfo,
  videoReviews: VideoReview[],
  videoToShow: string | null
) => {
  const [currentAutoPlayVideos, setCurrentAutoPlayVideos] =
    useState<VideoInfo | null>(videoDefaults);
  const [shiftedVideos, setShiftedVideos] = useState<VideoReview[] | null>(
    null
  );

  const handleCenterOnVideo = (videoIndex: number) => {
    let currentList = [...videoReviews];
    while (currentList[Math.floor(currentList.length / 2)].idx !== videoIndex) {
      const popped = currentList[currentList.length - 1];
      currentList.pop();
      currentList.unshift(popped);
    }
    setShiftedVideos(currentList);
  };

  useEffect(() => {
    if (videoToShow) {
      videoReviews.every((video) => {
        if (video.videoId.toString() === videoToShow) {
          handleCenterOnVideo(video.idx);
          return false;
        }
        return true;
      });
    }
  }, [videoToShow, videoReviews]);

  return {
    currentAutoPlayVideos,
    setCurrentAutoPlayVideos,
    shiftedVideos,
    handleCenterOnVideo,
  };
};
