import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { ErrorCodeMap } from "../../lib/Constants";
import { SetNewPassword } from "../../components/auth/AuthPages/SetNewPassword/SetNewPassword";
import { applyActionCode } from "firebase/auth";
import { auth } from "../../firebase";
import {
  Context,
  GetOrCreateSessionAuthContext,
} from "../../config/UserAuthContext";
import Cookies from "js-cookie";
import ChevronRight from "../../global-assets/assets/chevron-right.svg";
import Button from "../../components/common/Button";

interface IFBEmailRedirectProps {
  setAuth: (authenticated: boolean) => void;
}

export default function FBEmailRedirect({ setAuth }: IFBEmailRedirectProps) {
  const navigate = useNavigate();
  const userContext = GetOrCreateSessionAuthContext();
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [verifyEmailError, setVerifyEmailError] = useState<string | null>(null);
  const [params] = useSearchParams();
  const actionCode = params.get("oobCode") || "";
  const mode = params.get("mode") || "";

  let content = null;

  const handleEmailVerification = () => {
    const currentUser = userContext;
    currentUser.verifyEmail.isVerified = true;
    currentUser.verifyEmail.shouldShowEmailVerificationScreen = false;
    Cookies.set(Context.UserContext, JSON.stringify(currentUser));
    setLoading(false);
  };

  const handleVerifyEmail = async () => {
    if (isEmailVerified) {
      return;
    }
    await applyActionCode(auth, actionCode)
      .then((resp) => {
        setIsEmailVerified(true);
        handleEmailVerification();
      })
      .catch((error) => {
        const fbErrorCode = error.code;
        const fbErrorMessage = error.message;
        const errorMessage = ErrorCodeMap[fbErrorCode]
          ? ErrorCodeMap[fbErrorCode]
          : fbErrorMessage;
        setVerifyEmailError(errorMessage);
        setLoading(false);
      });
  };

  const handleResetPassword = () => {
    content = <SetNewPassword setAuth={setAuth} oobCode={actionCode} />;
  };

  if (isEmailVerified) {
    content = (
      <div className="flex h-full min-h-screen items-center justify-center px-4">
        <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-70" />
        <div className="flex w-[400px] flex-col items-center justify-center gap-4 rounded-[30px] bg-white p-4 py-6 md:p-6">
          <h2 className="text-center text-3xl text-indigo-500">
            Email Verified!
          </h2>
          <p className="text-coo-gray-900 m-0 text-center">
            Thank you for verifying your email.
          </p>
          <Button
            id="verified_account_settings_btn"
            onClick={() => navigate("/login")}
            btnType="green"
            rightIcon={
              <img
                src={ChevronRight}
                alt="Right Arrow"
                className="ml-4 h-5 w-5 sm:h-6 sm:w-6"
              />
            }
          >
            Account Settings
          </Button>
        </div>
      </div>
    );
  }

  if (!isEmailVerified && verifyEmailError) {
    content = (
      <div className="flex h-full min-h-screen items-center justify-center px-4">
        <div className="tru-single-modal-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-70" />
        <div className="flex w-[425px] flex-col items-center justify-center gap-4 rounded-[30px] bg-white p-4 py-6 md:p-6">
          <h2 className="text-center text-3xl text-indigo-500">
            Email Not Verified
          </h2>
          <p className="text-coo-gray-900 m-0 text-center">
            Your email was not able to be verified. Please navigate back to
            Account Settings and try verifying again.
          </p>
          <Button
            id="verified_account_settings_btn"
            onClick={() => navigate("/login")}
            btnType="green"
            rightIcon={
              <img
                src={ChevronRight}
                alt="Right Arrow"
                className="ml-4 h-5 w-5 sm:h-6 sm:w-6"
              />
            }
          >
            Account Settings
          </Button>
        </div>
      </div>
    );
  }

  if (loading) {
    content = (
      <div className="flex min-h-screen items-center justify-center bg-indigo-900">
        <PulseLoader color="#fff" />
      </div>
    );
  }

  switch (mode) {
    case "resetPassword":
      handleResetPassword();
      break;
    case "verifyEmail":
      handleVerifyEmail();
      break;
  }

  return <>{content}</>;
}
